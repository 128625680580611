import axios from 'axios';
import React, { useState } from 'react'
import ReactQuill from 'react-quill';
import "react-quill/dist/quill.snow.css";
import Swal from 'sweetalert2';

export default function Notification() {
    const [content, setContent] = useState({
        subject: '',
        message: ''
    });
    const modules = {
        toolbar: [
            ["bold", "italic", "underline", "strike"], // Text styling
            [{ list: "ordered" }, { list: "bullet" }], // Lists
            [{ indent: "-1" }, { indent: "+1" }], // Indentations
            [{ size: ["small", false, "large", "huge"] }], // Font sizes
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // Headers
            [{ color: [] }, { background: [] }], // Text color and background color
            [{ font: [] }], // Font family
            [{ align: [] }], // Text alignment
        ],
    };

    const formats = [
        "bold", "italic", "underline", "strike",
        "list", "bullet", "indent",
        "size", "header", "color", "background",
        "font", "align"
    ];    
    const handleChange = (value) => {
        setContent((prevContent) => ({
            ...prevContent,
            message: value, // Update only the 'message' field
        }));
    };

    const headleSubmit = async (e) => {
        e.preventDefault();
        try {

            if (!content.subject || !content.message) {
                Swal.fire({
                    title: "Please enter data",
                    icon: "warning",
                });
            } else {

                const res = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/sendNotification`, content);
                if (res.status === 200 && res.data.data) {
                    Swal.fire({
                        icon: "success",
                        title: "Notification sent Successfully",
                        timer: 1500,
                    })
                    setContent({
                        subject: '',
                        message: ''
                    });
                } else {
                    // Unsuccessful request
                    Swal.fire({
                        title: "Notification sent failed",
                        icon: "error",
                    });
                }
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <div className="page-wrapper page-settings">
                <div className="content">
                    <nav aria-label="breadcrumb" style={{ '--bs-breadcrumb-divider': 'none' }}>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item active" aria-current="page">Notification</li>
                        </ol>
                    </nav>
                    <div className="content-page-header content-page-headersplit">
                        <h5>Notification</h5>
                    </div>
                    <form onSubmit={headleSubmit}>
                        <div className="row">
                            <div className="col-10">
                                <div className="form-group">
                                    <label>Subject</label>
                                    <input
                                        type="text"
                                        name="subject"
                                        value={content.subject}
                                        className="form-control"
                                        onChange={(e) =>
                                            setContent((prevContent) => ({
                                                ...prevContent,
                                                subject: e.target.value
                                            }))
                                        }
                                        placeholder="Enter Subject"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="col-10">
                                <label>Message</label>
                                <div className="notary-writer-container">
                                    <ReactQuill
                                        value={content.message}
                                        onChange={handleChange}
                                        modules={modules}
                                        formats={formats}
                                        name="message"
                                        className="custom-quill"
                                    />
                                </div>
                                <div className="btn-path">
                                    <button type="submit" className="btn btn-primaryy" >Send</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
