import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

export default function Achievement() {

    const userRoll = JSON.parse(localStorage.getItem("aerialst"))
    const user_id = userRoll ? userRoll.id : null

    const [group, setGroup] = useState([]);
    const getSubsriptionData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getCertificateByUsesr/${user_id}`
            );
            if (res.status === 200) {
                setGroup(res.data.data);
            } else {
                setGroup([]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    useEffect(() => {
        getSubsriptionData();
    }, []);

    const handleimg = (e) => {
        e.target.src = "/assets/img/dance/TECHNIQUE WORKSHOP.JPG";
        e.target.alt = "Image not found";
    };

    const confettiPieces = Array.from({ length: 400 });
    return (
        <>
            <div className="dempp" style={{ backgroundImage: "url('/assets/img/hero/certificate.png')", backgroundSize: 'cover', backgroundPosition: ' center' }}>
                <h2>Achievement</h2>
                <p> <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}
                    to={'/'} style={{ color: 'white' }}>Home </Link> / Achievement </p>
            </div>

            {(!group || group.length === 0) ?
                <div></div>
                :
                <div className="confetti">
                    {confettiPieces.map((_, index) => (
                        <div
                            key={index} 
                            className="confetti-piece"
                            style={{
                                left: `${Math.random() * 100}%`, // Randomize horizontal position
                                transform: `rotate(${Math.random() * 360 - 180}deg)`, // Randomize rotation
                                animationDelay: `${Math.random() * 2500}ms`, // Randomize delay
                                animationDuration: `${Math.random() * 1000 + 1500}ms`, // Randomize duration
                            }}
                        />
                    ))}
                </div>
            }

            <section className="th-blog-wrapper mt-5">
                <div className="container">
                    <div className="row">
                        {(!group || group.length === 0) ?
                            <div style={{ textAlign: 'center', margin: '5% 0' }}>
                                <img style={{ height: '30vw' }} src="/assets/img/certificate.png" />
                            </div>
                            :
                            group.map((dataa, index) => {
                                return <div className="col-lg-6" key={index}>
                                    <div className="th-blog blog-single has-post-thumbnail box">
                                        <div className="blog-img">
                                            {dataa.photo && (
                                                dataa.photo.endsWith('.jpg') ||
                                                    dataa.photo.endsWith('.jpeg') ||
                                                    dataa.photo.endsWith('.png') ||
                                                    dataa.photo.endsWith('.gif') ? (
                                                    <>
                                                        <img
                                                            src={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${dataa.photo}`}
                                                            alt="Uploaded"
                                                            draggable={false}
                                                            style={{
                                                                width: "100%",
                                                                height: "300px",
                                                                objectFit: 'contain'
                                                            }}
                                                        />
                                                    </>
                                                ) : dataa.photo.endsWith('.pdf') ? (
                                                    <>
                                                        <iframe
                                                            src={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${dataa.photo}#toolbar=0&navpanes=0&scrollbar=0`}
                                                            draggable={false}
                                                            width="100%"
                                                            height="350px"
                                                            style={{
                                                                borderRadius: '20px',
                                                                width: "100%",
                                                                height: "300px",
                                                                objectFit: 'contain',
                                                                border: 'none'
                                                            }}
                                                        ></iframe>

                                                    </>
                                                ) : null
                                            )}

                                        </div>
                                        <div className="blog-content">
                                            <div className="blog-meta">
                                                <h4>{dataa.tabletitle}</h4>
                                                <h6>{dataa.level}</h6>
                                                <a
                                                    target="blank"
                                                    href={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${dataa.photo}`}
                                                    download={dataa.photo}
                                                >
                                                    <button className="th-btn" style={{ cursor: 'pointer' }} >Download</button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            })
                        }

                    </div>
                </div>
            </section>
        </>
    )
}
