import React from 'react'
import { Link } from "react-router-dom";


export default function Packages() {

  return (
    <div>
      <div className="container my-5">
        <div className="dance bgring1">
          <div className="row">
            <h3 style={{ textAlign: 'center' }}>OUR OFFERS/PACKAGES FOR BELLY DANCE & AERIAL ARTS CLASSES</h3>
            <p>1. Enroll for 3 months of Aerial classes and 2 months of Belly Dance to enjoy a 10% discount on total fees.</p>
            <p>2. Enroll for 6 months of Aerial classes and receive 2 free Belly Dance Choreography workshops. Offer valid for 6 months from the joining date.</p>
            <p>3. Enroll for 6 months of any 3 Belly Dance levels, get 1 month of Aerial classes free. Offer valid for 3 months from the joining date.</p>
            <p>4. Enroll for 5 Belly Dance workshops and get 4 free one-hour Aerial Silk classes. Offer valid for 2 months from booking.</p>
            <p>5. Enroll for 6 months of Aerial and 2 Belly Dance levels to get 4 free Belly Dance choreography workshops.</p>
            <p>6. Enroll for 12 Belly Dance Choreography workshops and receive 4 Aerial Silk classes and 1 month of Level 1 Belly Dance classes for free.</p>
            <p>7. Enroll for one year of Aerial classes and get a 2-month Belly Dance Level 1 course for free. Offer valid for 6 months from the Aerial class joining date.</p>
            <p>8. Enroll for 6 levels of Belly Dance and get 2 months of Aerial classes for free. Offer valid for 6 months from the Belly Dance class joining date.</p>
            <p>9. Enroll for all 16 levels of Belly Dance and receive 6 months of Aerial classes for free. Offer valid for one year from the date of joining Belly Dance Level 1.</p>
            <p>10. Enroll for 12 private Aerial Yoga/Aerial Silks classes and get one month of Belly Dance Level 1 group classes for free.</p>
            <p>11. Enroll for 24 private Aerial Yoga/Aerial Silk classes and get one month of Belly Dance Level 1 group classes and 4 Belly Dance choreography workshops for free.</p>
            <p>12. Enroll for the full package of Belly Dance 16 levels Private classes with Nupur Shah and get free entry to any group Belly Dance regular course classes for free for one year from the class joining date. Offer for online private class.</p>
            <p>13. Enroll for the full package of Belly Dance 16 levels Private classes with any senior instructor at AerialBelly and avail AerialBelly's membership of 8 months for free. Offer valid for 1 year from class joining date. Offer for offline studio in Ahmedabad private class students.</p>
            <p>14. Enroll for the full package of Belly Dance 16 levels Private classes with Nupur Shah and avail AerialBelly's annual (12 months) membership for free. Offer valid for 15 months from class joining date. Offer for offline studio in Ahmedabad private class students.</p>
            <p>15. 50% off on any student repeating any already completed level of Belly Dance if continuing with current level classes. </p>
            <p>16. 10% off on the overall fees of Aerial Arts classes on enrolling for 12 months group classes.</p>
          </div>
        </div>
      </div>

    </div>
  )
}



