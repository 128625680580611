import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";

function Shop() {
  const [data, setData] = useState([]);

  const getProductData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/getallproduct`
      );
      if (res.status === 200) {
        setData(res.data.data);
      } else {
        setData([]);
      }
    } catch (err) {
      console.log(err);
      // setLoader(true);
    }
  };
  useEffect(() => {
    getProductData();
  }, []);

  const handleimg = (e) => {
    e.target.src = "/assets/img/imgnot.png";
    e.target.alt = "Image not found";
  };

  return (
    <>

      {data.length === 0
        ? <div className="container" style={{ alignContent: 'center', textAlign: 'center' }}>
          <img src="/assets/img/shop.png" style={{ height: '50%' }} />
        </div>
        : <section className="space-top space-extra-bottom">
          <div className="container">
            <div className="row flex-row-reverse">

              <div className="container">
                <div className="row gy-40">
                  {data.map((dataa, id) => (
                    <div className="col-xl-3 col-lg-4 col-sm-6">
                      <div className="th-product product-grid">
                        <div className="product-img pic1">
                          {/* <img src="assets/img/product/product_1_1.jpg" alt="Product Image" /> */}
                          <img
                            src={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${dataa.photo}`}
                            style={{
                              // width: "70%",
                              height: "100%",
                              // borderRadius: "50%",
                              // margin: "5px 0",
                            }}
                            draggable={false}
                            onError={handleimg}
                          />
                          <div className="actions">
                            <Link
                              className="delete-table me-2"
                              onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}
                              to={`/productdetails/${dataa.id}`}
                            >
                              <i class="fa-regular fa-eye"></i>
                            </Link>
                          </div>
                        </div>
                        <div className="product-content">
                          {/* <div className="star-rating" role="img" aria-label="Rated 5.00 out of 5">
                          <span>Rated <strong className="rating">5.00</strong> out of 5 based on <span className="rating">1</span> customer rating</span>
                        </div> */}
                          <h3 className="product-title">{dataa.title}</h3>
                          <span className="price">₹ {dataa.amount}</span>
                        </div>
                      </div>
                    </div>
                  ))}

                </div>
              </div>
            </div>

          </div>
        </section>}

    </>
  )
}
export default Shop;