import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function About() {
  const [student, setStudent] = useState([]);

  const getStudentData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/getallbrand/2`
      );
      if (res.status === 200) {
        setStudent(res.data.data);
      } else {
        setStudent([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getStudentData();
  }, []);
  return (
    <>
      <div
        className="dempp"
        style={{
          backgroundImage: "url('/assets/img/hero/slider11.jpg')",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPositionY: "55%",
        }}
      >
        <h2 style={{ paddingTop: "170px" }}>About Us</h2>
        <p>
          <Link
            onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}
            to={"/"}
            style={{ color: "white" }}
          >
            Home{" "}
          </Link>{" "}
          / About Us
        </p>
      </div>

      <div className="overflow-hidden space" id="about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 my-5">
              <div className="img-box3">
                <div className="img1">
                  <img
                    src="assets/img/normal/Nupur  Shah9566-2.JPG"
                    style={{ borderRadius: "30px", height: "470px" }}
                    alt="About"
                  />
                </div>
                <div className="shape1">
                  <img src="assets/img/shape/about_shape_1.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="title-area mb-30">
                <h2 className="sec-title"> About Our Founder</h2>
              </div>
              <h2 className="sec-desc mt-n2 mb-30">Nupur Shah</h2>
              <h4 className="sec-title"> Founder of AerialBelly</h4>
              <Link to={"https://www.instagram.com/iamnupurkaushik?igsh=NzZ1bTJ3eTVwbW81"} target="blank">
                <i style={{ fontSize: '25px' }} className="fab fa-instagram"></i>
              </Link>
              <Link className="px-3" to={"mailto:letsworknupur@gmail.com"} target="blank">
                <i style={{ fontSize: '25px' }} className="fa-regular fa-envelope"></i>
              </Link>
              <Link to={"https://www.facebook.com/noopur.shah.03?mibextid=LQQJ4d"} target="blank">
                <i style={{ fontSize: '22px' }} className="fa-brands fa-facebook-f"></i>
              </Link>
              {/* <div className="about-btn mt-40"><Link to={'/loginpage'} className="th-btn gold-btn">Join Us Now</Link></div> */}
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-xl-12">
              <p className="sec-desc mt-n2 mb-30 mt-3">
                Nupur Shah is one of the head secretaries at the Aerial Sports Association of India. She is a professional Belly Dance
                artist and an Aerialist. She has been teaching and performing Belly Dance and Aerial Arts for the past 10 years. She
                has trained in Belly Dance and Aerial Arts with internationally acclaimed Belly Dancers (from Egypt, Dubai, Europe, and
                South America) and Aerialists. Before pursuing Belly Dance and Aerial Arts, she was a freestyle dancer and choreographer
                . She has trained in various dance forms as well. Nupur was a professional model and won the title of Miss Gujarat 2014.
                She was among the top 40 contestants in Femina Miss Diva Universe 2014. After coining her own dance style, AerialBelly,
                she auditioned for a TV dance reality show called Main Hoon Michael (judged by Bollywood celebrities Tiger Shroff and
                Nawazuddin Siddiqui), where she became one of the top 9 contestants. She then received opportunities to perform Belly
                Dance in various TV shows as a guest artist and later did Aerial and Belly Dance shows nationally and internationally.
                Nupur has won a bronze medal in the National Aerial Sports Championship and has appeared on regional channels for
                her contributions to the field of art. She started her career in Pune and later moved to Bombay. Her classes are now
                held in Mumbai and Ahmedabad. Her brainchild, AerialBelly, gained recognition in 2020 on national TV (Zee TV & Zee
                Cafe) in the show called Dance with Me, hosted and judged by Shakti Mohan and Mukti Mohan. She now has her team working
                in Mumbai and Ahmedabad, performing all over the world, and has her institute in Ahmedabad, named AerialBelly Studio.
              </p>
            </div>
          </div>
        </div>
        <div
          className="shape-mockup movingX d-none d-xxl-block"
          data-top="0%"
          data-right="0%"
        >
          <img src="assets/img/shape/shape_1.png" alt="shape" />
        </div>
      </div>


      <div className="overflow-hidden bgringab" id="about-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <div className="img-box3">
                <div className="img1">
                  <img
                    src="assets/img/normal/ab-aeriel.jpg"
                    style={{ marginTop: "8%", borderRadius: "30px" }}
                    alt="About"
                  />
                </div>
                <div className="shape1">
                  <img src="assets/img/shape/about_shape_1.svg" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="title-area mb-30">
                <h2 className="sec-title"> About AerialBelly</h2>
              </div>
              <p className="sec-desc mt-n2 mb-30">
                AerialBelly is a copyrighted dance form. This dance form was
                formed in the year 2017. AerialBelly is the brainchild of Ms.
                Nupur Shah. The concept of this dance form is to belly dance in
                the air/on an Aerial prop. This absolutely mesmerizing dance
                form is still new in the world art industry but it has succeeded
                in making its name among some of the most talented and
                celebrated artists of India like Shakti Mohan and Mukti Mohan.
                It is on its way to grow and shine more!
              </p>

              {/* <div className="about-btn mt-40"><Link to={'/loginpage'} className="th-btn gold-btn">Join Us Now</Link></div> */}
            </div>
          </div>
        </div>
        <div
          className="shape-mockup movingX d-none d-xxl-block"
          data-top="0%"
          data-right="0%"
        >
          <img src="assets/img/shape/shape_1.png" alt="shape" />
        </div>
      </div>

      <div className="overflow-hidden space-top" id="about-sec">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-xl-6">
              <div className="title-area mb-30">
                <h2 className="sec-title">AerialBelly Studio</h2>
              </div>
              <p className="sec-desc mt-n2 mb-30">
                Our prime location offers an outdoor Aerial setup 22 feet above the ground, blending art and nature seamlessly.
                Experience Aerial Dance and Yoga in the serene outdoor space surrounded by lush greenery with a stunning sunrise view.
                Our spacious indoor studio, equipped with professional amenities, Aerial Yoga setup, mirrors, and dedicated online
                class areas, provides a tranquil heaven for dance & yoga enthusiasts. Our studio is known for offering authentic
                Belly Dance classes and workshops, as well as Aerial Arts classes for all the age groups. Join us to unleash your
                creativity and celebrate the harmony of art and nature!
              </p>
              <div className="about-btn mt-40">
                <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}
                  to={"/studiorental"} className="th-btn gold-btn">
                  Book  Studio Now{" "}
                </Link>
              </div>
            </div>
            <div className="col-xl-6 my-4">
              <iframe
                width="600"
                height="315"
                src="https://www.youtube.com/embed/d7H4A0udgTI?si=bMFIbjE-9qrokysL&amp;start=7"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div>
        <div
          className="shape-mockup movingX d-none d-xxl-block"
          data-top="0%"
          data-right="0%"
        >
          <img src="assets/img/shape/shape_1.png" alt="shape" />
        </div>
      </div>

      <div className="overflow-hidden space bgring" id="about-sec">
        <div className="container space-top">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <div className="img-box3">
                <div className="img1">
                  <img src="assets/img/team/live7.jpeg" style={{ borderRadius: '30px', height: '500px' }} alt="Team" />
                </div>
                <div className="shape1"><img src="assets/img/shape/about_shape_1.svg" alt="shape" /></div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="title-area">
                <h2 className="sec-title">AerialBelly Head Instructor</h2>
                <h3 className="sec-title mt-3"> Deepika Thakor</h3>
                <p className="sec-desc mt-n2 mb-30" style={{ paddingTop: '5%' }}>
                  Introducing Deepika Thakor, a seasoned BellyDance and Aerial Arts instructor at AerialBelly studio. With international
                  training in both Belly Dance and Aerial Arts, she brings a unique expertise to her classes. She is also a trained Kathak
                  dancer and also a professional model. Deepika has honed her skills under the guidance of AerialBelly's founder, Nupur
                  Shah, and boasts a rich experience of 3-4 years, including captivating performances in professional Belly Dance shows.
                </p>
                {/* <h4 className="sec-title" style={{ paddingTop: '5%' }}> Founder Of AerialBelly Dance Style</h4> */}
              </div>
            </div>
          </div>

        </div>
        <div className="shape-mockup movingX d-none d-xxl-block" data-top="0%" data-right="0%"><img
          src="assets/img/shape/shape_1.png" alt="shape" /></div>
      </div>



      <section className="space">
        <div className="container">
          <div className="title-area text-center">
            <span className="sub-title mt-n1">we believe in excellence</span>
            <h2 className="sec-title">OUR PROFESSIONAL INSTRUCTORS</h2>
            <p>Team AerialBelly, a distinguished troupe specializing in Belly Dance and Aerial Arts under the expert guidance of our
              founder, Ms. Nupur Shah. as well as other international instructors. Led by one of the senior most and supremely talented & trained instructors, Deepika Thakor, along with
              other prominent members, the AerialBelly team is renowned for their captivating performances and comprehensive teaching
              methods in both Belly Dance and Aerial Arts. With a minimum of two years of experience in performing and instructing
              worldwide, our team is committed to delivering authentic, methodical, and enjoyable learning experiences in these 2
              beautiful art forms.</p>
          </div>
          <div
            className="row slider-shadow th-carousel"
            data-slide-show="4"
            data-lg-slide-show="3"
            data-md-slide-show="2"
            data-sm-slide-show="2"
            data-xs-slide-show="1"
            data-arrows="true"
          >
            {student.map((dataa, i) => {
              return <div key={i} className="col-sm-6 col-lg-4">
                <div className="th-team team-card my-3">
                  <div className="team-img">
                    <img src={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${dataa.photo}`} alt="Team" />
                  </div>
                  <div className="team-content">
                    <h3 className="team-title box-title">
                      <a href="single-instructor.html">{dataa.name}</a>
                    </h3>
                    {/* <span className="team-desig">Instructor</span> */}
                  </div>
                  <div className="team-social">
                    <Link to={"https://youtube.com/@nupurshahh?si=77KsUw4I3NB4rya0"} target="blank">
                      <i className="fa-brands fa-youtube"></i>
                    </Link>
                    <Link to={"https://www.instagram.com/aerialbelly?igsh=MnNrYmdjYnpmY2w1"} target="blank">
                      <i className="fab fa-instagram"></i>
                    </Link>
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </section>
      <style jsx>{`
                .ab_report th, td {
                    padding: 8px;
                    text-align: initial;
                    }

                    @media (max-width: 1024px) {
                      p.sec-desc.mt-n2.mb-30 {
                        padding-top: 0 !important;
                    }
                    }
            `}</style>
    </>
  );
}
export default About;
