import { useState } from "react";
import LocContext from "./locContext"
import axios from "axios";

const LocState = (props) => {
    const location = JSON.parse(localStorage.getItem("userPlace"));
    if (!location) {
        const fetchCountry = async () => {
            try {
                const response = await axios.get('https://ipinfo.io/json');
                const data = response.data;
                localStorage.setItem("userPlace", JSON.stringify(data.country));
            } catch (error) {
                console.error('Error fetching country:', error);
            }
        };
        fetchCountry()
    } else {
        console.log('');
    }
    
    //      Cart 
    const userRollid = JSON.parse(localStorage.getItem("aerialst")) || 0;
    const id = userRollid.id;
    const [data, setData] = useState([])
    const getAddtoCartData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getAddToCart/${id}`
            );
            if (res.status === 200) {
                setData(res.data.data)
            } else {
                setData([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <LocContext.Provider value={{ location, getAddtoCartData, data }}>
            {props.children}
        </LocContext.Provider>
    )
}

export default LocState;