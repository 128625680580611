import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Swal from "sweetalert2";
import locContext from "../context/locContext";

function BellyDanceWorkShop() {
  const { location, getAddtoCartData } = useContext(locContext);

  const userRoll = JSON.parse(localStorage.getItem("aerialst"))
  const user_id = userRoll ? userRoll.id : null
  const navigate = useNavigate();

  const [group, setGroup] = useState([]);
  const getSubsriptionData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_BASE_URL}/getAllWorkshops`
      );
      if (res.status === 200) {
        const formattedData = res.data.data.map(item => ({
          ...item,
          date: formatDate(item.date),
        }));
        setGroup(formattedData);
      } else {
        setGroup([]);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}-${month}-${year}`;
  };
  useEffect(() => {
    getSubsriptionData();
  }, []);

  const [cart, setCart] = useState({
    "user_id": user_id,
    "cart_type": 2,
  });

  const addGroupcart = async (id) => {
    try {
      if (!user_id) {
        navigate("/loginpage");
      } else {
        const res = await axios.post(`${process.env.REACT_APP_BACKEND_BASE_URL}/addToCart/${id}`, cart);

        if (res.status === 200 && res.data.data) {
          Swal.fire({
            icon: "success",
            title: "This Add to cart",
            timer: 1500,
          })
          getAddtoCartData();
        } else if (res.status === 201) {
          Swal.fire({
            icon: "warning",
            title: res.data.msg,
            confirmButtonText: "OK",
            confirmButtonColor: "#06bdff",
          });
        }
      }
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 400) {
        Swal.fire({
          icon: "warning",
          title: "This Data Already Exists",
          confirmButtonText: "OK",
          confirmButtonColor: "#06bdff",
        });
      } else {
        // setLoader(true)
      }
    }

  }
  const handleimg = (e) => {
    e.target.src = "/assets/img/dance/TECHNIQUE WORKSHOP.JPG";
    e.target.alt = "Image not found";
  };
  return (
    <>
      <div className="dempp" style={{ backgroundImage: "url('/assets/img/hero/ImportedPhoto_1722864109271.jpg')", backgroundSize: 'cover', backgroundPosition: ' center' }}>
        <h2>Belly Dance WorkShop</h2>
        <p> <Link onClick={() => window.scrollTo({ top: 0, behavior: "instant" })}
          to={'/'} style={{ color: 'white' }}>Home </Link> / Belly Dance WorkShop </p>
      </div>



      {group.length === 0
        ? <div className="container my-5">
          <div className="row">
            <div className="container">
              <div className="dance bgring1">
                <div className="row">
                  <div className="col-md-5">
                    <div className="tab-img1">
                      <img src="/assets/img/dance/TECHNIQUE WORKSHOP.JPG" alt="Aerial Belly" />

                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="tab-text1 my-5">
                      <h4>Refine Your Belly Dance Moves</h4>
                      <p>
                        Join AerialBelly Studio for a<b style={{ fontFamily: 'Poppins' }}> monthly 2-hour Belly Dance Choreography Workshop</b> led by the talented Nupur Shah. Each month, you’ll have the opportunity to master captivating sequences that blend Belly Dance with the latest Bollywood and non-Egyptian beats. Whether you're a seasoned dancer or just starting, Nupur's expert guidance ensures a rewarding learning experience. Don't miss the chance to stay in sync with the latest dance trends! Mark your calendar for an immersive session filled with creativity and rhythm. Unleash the dancer within you!
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        : <section className="th-blog-wrapper mt-5">
          <div className="container">
            <div className="row">
              {group.map((dataa, index) => {
                return <div className="col-lg-6" key={index}>
                  <div className="th-blog blog-single has-post-thumbnail box">
                    <div className="blog-img">
                      {dataa.status === 1
                        ? <div className="ribbon ribbon-top-right"><span>SOLD OUT</span></div>
                        : " "
                      }

                      <img
                        src={`${process.env.REACT_APP_BACKEND_BASE_URL}/uploads/${dataa.photo}`}
                        draggable={false}
                        style={{
                          width: "100%",
                          height: "350px",
                          objectFit: 'contain'
                        }}
                        onError={handleimg}
                      />
                    </div>
                    <div className="blog-content">
                      <div className="blog-meta">
                        <Link>
                          <i className="fa-light fa-calendar-days"></i>
                          {dataa.date}
                        </Link>
                        <Link to={dataa.location} target="block">
                          <i className="fa-solid fa-location-dot"></i>
                          {dataa.city}
                        </Link>
                      </div>
                      <div className="d-flex justify-content-between">
                        <h2 className="blog-title">{dataa.title}</h2>
                        <h6 className="blog-text">{dataa.type}</h6>
                      </div>
                      <p className="blog-text" style={{ whiteSpace: 'pre-line' }}>{dataa.description}</p>


                      {dataa.status === 1
                        ? ''
                        : <div>
                          {location === "IN"
                            ? <p className="blog-title">₹ {dataa.rupees}</p>
                            : <p className="blog-title">$ {dataa.doller}</p>
                          }
                          <button className="th-btn" style={{ cursor: 'pointer' }} onClick={() => addGroupcart(dataa.id)}>Add To Cart</button>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              })}
            </div>
          </div>
        </section>}
    </>
  )
}
export default BellyDanceWorkShop;