import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import locContext from '../../context/locContext';
import { Link } from 'react-router-dom';

export default function Amount() {
    const { location } = useContext(locContext);
    const [loader, setLoader] = useState(false);

    //        Group Belly Dance Classes 
    const [group, setGroup] = useState([]);
    const getSubsriptionData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/1`
            );
            if (res.status === 200) {
                setGroup(res.data.data);
            } else {
                setGroup([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    //    Private Belly Dance Classes With Our Senior Instructor
    const [privatea, setPrivatea] = useState([]);
    const getPrivateData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/2`
            );
            if (res.status === 200) {
                setPrivatea(res.data.data);
            } else {
                setPrivatea([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    //    Private Belly Dance Classes With Nupur Shah
    const [privateaclass, setPrivateaclass] = useState([]);
    const getPrivateclassData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/3`
            );
            if (res.status === 200) {
                setPrivateaclass(res.data.data); // Corrected here
            } else {
                setPrivateaclass([]); // Corrected here
            }
        } catch (err) {
            console.log(err);
        }
    };

    //       Monthly Belly Dance Choreography Classes
    const [privateaclassmonthly, setPrivateaclassmonthly] = useState([]);
    const getPrivateclassmonthlyData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/4`
            );
            if (res.status === 200) {
                setPrivateaclassmonthly(res.data.data); // Corrected here
            } else {
                setPrivateaclassmonthly([]); // Corrected here
            }
        } catch (err) {
            console.log(err);
        }
    };


    //          Silks
    const [groupSilk, setGroupSilk] = useState([]);
    const getSubsriptionDataSilk = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/5`
            );
            if (res.status === 200) {
                setGroupSilk(res.data.data);
            } else {
                setGroupSilk([]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const [privateaSilk, setPrivateaSilk] = useState([]);
    const getPrivateDataSilk = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/6`
            );
            if (res.status === 200) {
                setPrivateaSilk(res.data.data);
            } else {
                setPrivateaSilk([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    //          Yoga
    const [yoga, setYoga] = useState([]);
    const getYogaData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/9`
            );
            if (res.status === 200) {
                setYoga(res.data.data);
            } else {
                setYoga([]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const [pryoga, setPrYoga] = useState([]);
    const getPrYogaData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/16`
            );
            if (res.status === 200) {
                setPrYoga(res.data.data);
            } else {
                setPrYoga([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    //          Hammock
    const [hammock, setHammock] = useState([]);
    const getHammockData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/10`
            );
            if (res.status === 200) {
                setHammock(res.data.data);
            } else {
                setHammock([]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const [prhammock, setPrHammock] = useState([]);
    const getPrHammockData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/17`
            );
            if (res.status === 200) {
                setPrHammock(res.data.data);
            } else {
                setPrHammock([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    //          Hoop
    const [hoop, setHoop] = useState([]);
    const getHoopData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/11`
            );
            if (res.status === 200) {
                setHoop(res.data.data);
            } else {
                setHoop([]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const [prhoop, setPrHoop] = useState([]);
    const getPrHoopData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/18`
            );
            if (res.status === 200) {
                setPrHoop(res.data.data);
            } else {
                setPrHoop([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    //          Trapeze
    const [trapeze, setTrapeze] = useState([]);
    const getTrapezeData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/12`
            );
            if (res.status === 200) {
                setTrapeze(res.data.data);
            } else {
                setTrapeze([]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const [prtrapeze, setPrTrapeze] = useState([]);
    const getPrTrapezeData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/19`
            );
            if (res.status === 200) {
                setPrTrapeze(res.data.data);
            } else {
                setPrTrapeze([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    //          Cube
    const [cube, setCube] = useState([]);
    const getCubeData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/13`
            );
            if (res.status === 200) {
                setCube(res.data.data);
            } else {
                setCube([]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const [prcube, setPrCube] = useState([]);
    const getPrCubeData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/20`
            );
            if (res.status === 200) {
                setPrCube(res.data.data);
            } else {
                setPrCube([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    //          Toddler
    const [toddler, setToddler] = useState([]);
    const getToddlerData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/22`
            );
            if (res.status === 200) {
                setToddler(res.data.data);
            } else {
                setToddler([]);
            }
        } catch (err) {
            console.log(err);
        }
    };
    const [prtoddler, setPrToddler] = useState([]);
    const getPrToddlerData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/23`
            );
            if (res.status === 200) {
                setPrToddler(res.data.data);
            } else {
                setPrToddler([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    //          Combo
    const [combo, setCombo] = useState([]);
    const getComboData = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/14`
            );
            if (res.status === 200) {
                setCombo(res.data.data);
            } else {
                setCombo([]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    //        Studio Rental & Collaboration
    const [rent, setRent] = useState([]);
    const getRent = async () => {
        try {
            const res = await axios.get(
                `${process.env.REACT_APP_BACKEND_BASE_URL}/getsubscriptionbytitle/7`
            );
            if (res.status === 200) {
                setRent(res.data.data);
            } else {
                setRent([]);
            }
        } catch (err) {
            console.log(err);
        }
    };


    useEffect(() => {
        getSubsriptionData();
        getPrivateData();
        getPrivateclassData();
        getPrivateclassmonthlyData();
        getSubsriptionDataSilk();
        getPrivateDataSilk();
        getYogaData();
        getPrYogaData();
        getHammockData();
        getPrHammockData();
        getHoopData();
        getPrHoopData();
        getTrapezeData();
        getPrTrapezeData();
        getCubeData();
        getToddlerData();
        getPrToddlerData();
        getPrCubeData();
        getComboData();
        getRent();
    }, []);

    return (
        <div>

            {loader || (
                <div className="page-wrapper page-settings">
                    <div className="content">
                        <nav
                            aria-label="breadcrumb"
                            style={{ "--bs-breadcrumb-divider": "none" }}
                        >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item active" aria-current="page">
                                    Pricing
                                </li>
                            </ol>
                        </nav>

                        {/*            Group Belly Dance             */}
                        <div className="content-page-header content-page-headersplit" style={{ margin: '0' }}>
                            <h5>Group Belly Dance Classes</h5>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>

                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>1 MONTH</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>2 MONTH</th>
                                                    </tr>
                                                    {group.slice(0, 16).map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center', wordSpacing: '25px' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                {"$" + dataa.doller_1}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center', wordSpacing: '25px' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                {"$" + dataa.doller_2}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*           Private Belly Dance Classes With Our Senior Instructor           */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h5>Private Belly Dance Classes With Our Senior Instructor</h5>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DROP IN</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>8 CLASSES</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>16 CLASSES</th>
                                                    </tr>

                                                    {privatea.map((dataa, idex) => {
                                                        const previousLevel = group[idex - 1];
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center', wordSpacing: '25px' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                {"$" + dataa.doller_1}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center', wordSpacing: '25px' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                {"$" + dataa.doller_2}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center', wordSpacing: '25px' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                {"$" + dataa.doller_3}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*           Private Belly Dance Classes With Nupur Shah           */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h5>Private Belly Dance Classes With Nupur Shah</h5>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DROP IN</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>8 CLASSES</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>16 CLASSES</th>
                                                    </tr>

                                                    {privateaclass.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center', wordSpacing: '25px' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                {"$" + dataa.doller_1}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center', wordSpacing: '25px' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                {"$" + dataa.doller_2}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center', wordSpacing: '25px' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                {"$" + dataa.doller_3}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*           Monthly Belly Dance Choreography Classes          */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h5>Monthly Belly Dance Choreography Classes</h5>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>1 Month</th>
                                                    </tr>

                                                    {privateaclassmonthly.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center', wordSpacing: '25px' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                {"$" + dataa.doller_1}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*          Aerial Arts Classes          */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h4>Aerial Arts Classes</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6>Group Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>1 Months (8 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>2 Months (16 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>3 Months (24 CLASSES)</th>
                                                    </tr>

                                                    {groupSilk.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_4 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_4_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <h6>Private Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>12 CLASSES (Validity 45 days)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>24 CLASSES (Validity 90 days)</th>
                                                    </tr>

                                                    {privateaSilk.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*          Aerial Yoga Classes         */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h4>Aerial Yoga Classes</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6>Group Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>1 Months (8 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>2 Months (16 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>3 Months (24 CLASSES)</th>
                                                    </tr>

                                                    {yoga.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_4 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_4_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <h6>Private Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>12 CLASSES (Validity 45 days)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>24 CLASSES (Validity 90 days)</th>
                                                    </tr>

                                                    {pryoga.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*         Aerial Hammock Classes        */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h4>Aerial Hammock Classes</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6>Group Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>1 Months (8 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>2 Months (16 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>3 Months (24 CLASSES)</th>
                                                    </tr>

                                                    {hammock.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_4 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_4_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <h6>Private Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>12 CLASSES (Validity 45 days)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>24 CLASSES (Validity 90 days)</th>
                                                    </tr>

                                                    {prhammock.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*         Aerial Hoop / Lyra Classes        */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h4>Aerial Hoop / Lyra Classes</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6>Group Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>1 Months (8 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>2 Months (16 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>3 Months (24 CLASSES)</th>
                                                    </tr>

                                                    {hoop.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_4 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_4_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <h6>Private Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>12 CLASSES (Validity 45 days)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>24 CLASSES (Validity 90 days)</th>
                                                    </tr>

                                                    {prhoop.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*         Aerial Trapeze Classes        */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h4>Aerial Trapeze Classes</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6>Group Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>1 Months (8 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>2 Months (16 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>3 Months (24 CLASSES)</th>
                                                    </tr>

                                                    {trapeze.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_4 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_4_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <h6>Private Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>12 CLASSES (Validity 45 days)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>24 CLASSES (Validity 90 days)</th>
                                                    </tr>

                                                    {prtrapeze.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*         Aerial Cube Classes        */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h4>Aerial Cube Classes</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6>Group Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>1 Months (8 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>2 Months (16 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>3 Months (24 CLASSES)</th>
                                                    </tr>

                                                    {cube.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_4 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_4_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <h6>Private Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>12 CLASSES (Validity 45 days)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>24 CLASSES (Validity 90 days)</th>
                                                    </tr>

                                                    {prcube.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*         Aerial Toddlers Classes        */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h4>Aerial Toddler Classes</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <h6>Group Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>1 Months (8 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>2 Months (16 CLASSES)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>3 Months (24 CLASSES)</th>
                                                    </tr>

                                                    {toddler.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_4 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_4_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 mt-3">
                                <h6>Private Class</h6>
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>LEVEL</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>DEMO CLASS</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>12 CLASSES (Validity 45 days)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>24 CLASSES (Validity 90 days)</th>
                                                    </tr>

                                                    {prtoddler.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>







                        {/*         Aerial Arts Combo Classes        */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h4>Aerial Arts Combo Classes</h4>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>Combo Classe</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>8 Classes in 1 Month (2/week)	</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>16 Classes in 1 Month (2/week)</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>24 Classes in 3 Months</th>
                                                        <th className="author mb-0" style={{ fontWeight: '700' }}>48 Classes in 3 Months
                                                        </th>
                                                    </tr>

                                                    {combo.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_1 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_2 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_2_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_3 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_3_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                            <td className="title mb-0" style={{ textAlign: 'center' }}>
                                                                {"₹" + dataa.rupees_4 + " "}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_4_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        {/*           Studio Rental & Collaborations           */}
                        <div className="content-page-header content-page-headersplit mt-4" style={{ margin: '0' }}>
                            <h5>Studio Rental & Collaborations</h5>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <div className="tab-content tab-schedule-content" id="nav-tabContent" style={{ width: '100%' }}>
                                        <div className="tab-pane fade active show" id="nav-step1" role="tabpanel">
                                            <div className="schedule-details">
                                                <table style={{ textAlign: 'center' }}>
                                                    <tr className="schedule-content" style={{ backgroundColor: '#bd934c' }}>
                                                        <th className="time mb-0" style={{ fontWeight: '700' }}>Name</th>
                                                        <th className="title mb-0" style={{ fontWeight: '700' }}>Price</th>
                                                    </tr>

                                                    {rent.map((dataa, idex) => {
                                                        return <tr className="schedule-content" key={idex}>
                                                            <td className="time mb-0" style={{ textAlign: 'center' }}>{dataa.level_name}</td>
                                                            <td className="title mb-0" style={{ textAlign: 'center', wordSpacing: '25px' }}>
                                                                {"₹" + dataa.rupees_1}
                                                                <Link className="btn btn-primary btn-xsm" to={`/editprice/${dataa.le_1_id}`} style={{ padding: '3px 8px', marginLeft: '8%', fontSize: '11px' }}>
                                                                    <i className="fas fa-edit"></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    })}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}